<template>
  <b-card class="p-0 m-0">
    <!-- <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :to="{ name: 'new-product' }"
          variant="primary"
          class="btn-icon"
        >
          Aggiungi Prodotto new
        </b-button>
      </b-col>
    </b-row> -->
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <filters-badge-syncfusion
      :filtersUsed="filtersSearchBegin"
      @clear-filter="clearFilter"
    />
    <!-- <div
      v-if="!isDesktop"
    >
      <div>
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          class=""
          locale="it-IT"
          height="auto"
          :data-source="products"
          :enableAdaptiveUI="true"
          :rowRenderingDirection="rowModeMobile"
          :edit-settings="{ allowAdding: false, allowEditing: false, allowDeleting: false, mode: 'Dialog' }"
          :selection-settings="selectionSettings"
          :allow-filtering="true"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-resizing="true"
          :filter-settings="filterOptions"
          row-height="38"
          :load="load"
          :toolbar="toolbarMobile"
          :toolbar-click="toolbarClick"
          :command-click="commandColumm"
          :allow-paging="true"
          :page-settings="pageSettings"
          :row-selected="onRowSelected"
          :cell-deselecting="onDeselectRow"
          :data-bound="dataBound"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="40"
            />
            <e-column
              field="id"
              header-text="id"
              :is-primary-key="true"
              width="20"
              :visible="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="codice"
              header-text="Codice"
              width="100"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="prezzoVendita"
              header-text="Prezzo"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :auto-fit="true"
            />
            <e-column
              field="costo"
              header-text="Costo"
              width="200"
              :visible="false"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :auto-fit="true"
            />
            <e-column
              field="unitaUtilizzo"
              header-text="Unità Utilizzo"
              width="200"
              :visible="false"
              clip-mode="EllipsisWithTooltip"
              :filter="filter"
            />
            <e-column
              field="unitaMisura"
              header-text="UM"
              width="200"
              :visible="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="tipoProdotto"
              header-text="Tipologia"
              width="140"
              :visible="false"
              :filter="filter"
            />
            <e-column
              field="attivo"
              header-text="Attivo"
              width="100"
              :template="templateAttivo"
              :visible="false"
              text-align="Center"
              :auto-fit="true"
            />
            <e-column
              field="disponibile"
              header-text="Disponibile"
              width="100"
              :template="templateDisponibile"
              :visible="false"
              text-align="Center"
              :auto-fit="true"
            />
            <e-column
              field="inEvidenza"
              header-text="In Evidenza"
              width="100"
              :filter="filter"
              :template="templateInEvidenza"
              :visible="false"
              text-align="Center"
              :auto-fit="true"
            />
            <e-column
              field="visibile"
              header-text="Visibile"
              width="100"
              :filter="filter"
              :visible="false"
              :template="templateVisibile"
              text-align="Center"
              :auto-fit="true"
            />
            <e-column
              field="descrizioneBreve"
              header-text="Descrizione"
              width="170"
              :filter="filter"
              :visible="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="note"
              header-text="Note"
              width="150"
              :filter="filter"
              :visible="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="150"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div> -->
    <div
    >
      <!-- table desktop-->
      <ejs-grid
        id="overviewgrid"
        ref="overviewgrid"
        locale="it-IT"
        :data-source="products"
        :edit-settings="editSettings"
        :selection-settings="selectionSettings"
        :allow-text-wrap="false"
        :allow-filtering="true"
        :filter-settings="filterOptions"
        :show-column-chooser="true"
        :allow-selection="true"
        :allow-sorting="true"
        :allow-resizing="true"
        :enable-virtualization="false"
        :allow-row-drag-and-drop="false"
        height="600"
        :allow-excel-export="true"
        :allow-pdf-export="true"
        row-height="38"
        :load="load"
        :toolbar="toolbar"
        :toolbar-click="toolbarClick"
        :allow-grouping="true"
        :allow-paging="true"
        :page-settings="pageSettings"
        :context-menu-items="contextMenuItems"
        :context-menu-click="contextMenuClick"
        :command-click="commandColumm"
        :row-selected="onRowSelected"
        :cell-deselecting="onDeselectRow"
        :action-complete="actionComplete"
        :action-begin="actionBegin"
      >
        <e-columns>
          <e-column
            type="checkbox"
            :allow-filtering="false"
            :allow-sorting="false"
            width="70"
          />
          <e-column
            field="id"
            header-text="id"
            :is-primary-key="true"
            width="200"
            :visible="false"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="codice"
            header-text="Codice"
            width="100"
            :filter="filter"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="nome"
            header-text="Nome"
            width="200"
            :filter="filter"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="tipoProdotto"
            header-text="Tipologia"
            width="140"
            :template="templateTipoProdotto"
            :filter="filter"
          />
          <e-column
            field="prezzoVendita"
            header-text="Prezzo"
            width="200"
            :filter="filter"
            :auto-fit="true"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="costo"
            header-text="Costo"
            width="200"
            :filter="filter"
            :auto-fit="true"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="unitaUtilizzo"
            header-text="Unità Utilizzo"
            width="120"
            :visible="false"
            :filter="filter"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="unitaMisura"
            header-text="UM"
            width="120"
            :filter="filter"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="statoServizio"
            header-text="Stato"
            width="170"
            :visible="false"
            :filter="filter"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="attivo"
            header-text="Attivo"
            width="100"
            :filter="filter"
            :template="templateAttivo"
            text-align="Center"
          />
          <e-column
            field="disponibile"
            header-text="Disponibile"
            width="100"
            :filter="filter"
            :template="templateDisponibile"
            text-align="Center"
          />
          <!-- <e-column
            field="inEvidenza"
            header-text="In Evidenza"
            width="100"
            :filter="filter"
            :template="templateInEvidenza"
            text-align="Center"
          />
          <e-column
            field="visibile"
            header-text="Visibile"
            width="100"
            :filter="filter"
            :template="templateVisibile"
            text-align="Center"
          /> -->
          <e-column
            field="descrizioneBreve"
            header-text="Descrizione"
            width="170"
            :filter="filter"
            :visible="false"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="note"
            header-text="Note"
            width="150"
            :filter="filter"
            :visible="false"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="Azioni"
            :lock-column="true"
            header-text="Azioni"
            width="150"
            :allow-editing="false"
            :commands="commands"
            :allow-sorting="false"
            :allow-filtering="false"
            hide-at-media="(min-width:900px)"
          />
        </e-columns>
      </ejs-grid>
      <!-- <product-not-associate ref="productsNotAssociate" /> -->
    </div>
  </b-card>
</template>

<script>
import Vue from 'vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import {
  BCard, VBToggle, BAvatar, BBadge,
} from 'bootstrap-vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate, Edit, Search,
} from '@syncfusion/ej2-vue-grids'
import { alertProduct } from '@core/mixins/ui/alert'
import { L10n, setCulture, Browser } from '@syncfusion/ej2-base'
import moment from 'moment'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { getProdotti } from '@/@core/api-service/catalog/products'
import { italian } from '@/@core/grid-translation/it-IT'
import FiltersBadgeSyncfusion from '@/@core/components/filter-table-syncfusion/FiltersBadgeSyncfusion.vue'
/* import ProductNotAssociate from './products/details/sidebarEventHandler/ProductNotAssociate.vue' */

Vue.use(GridPlugin)
Vue.use(ToastPlugin)

setCulture('it-IT')
L10n.load(italian)

export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    // BContainer,
    BCard,
    FiltersBadgeSyncfusion,
    // ProductNotAssociate,
  },
  mixins: [alertProduct],
  data() {
    return {
      filtersSearchBegin: [],
      rowModeMobile: 'Vertical',
      rowMode: 'Horizontal',
      wrapSettings: { wrapMode: 'Content' },
      // codeColumnSearch,
      isDesktop: !Browser.isDevice,
      products: [],
      filterSettingsMobile: {
        type: 'Menu',
      },
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'Menu',
      },
      selectionSettings: {
        persistSelection: false, type: 'Multiple', checkboxOnly: true,
      },
      commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        {
          type: 'Dettagli',
          id: 'Dettagli',
          target: '.e-column',
          buttonOption: {
            iconCss: 'e-icons e-settings',
            cssClass: 'e-flat',
          },
        }],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Save',
        'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup',
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        },
        {
          text: 'Dettagli',
          target: '.e-content',
          id: 'Dettagli',
          iconCss: 'e-icons e-folder-open',
        },
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      showModalSettingPdfExport: false,
      editSettings: {
        showDeleteConfirmDialog: false,
        allowEditing: false,
        allowAdding: false,
        allowDeleting: true,
        mode: 'Normal',
      },
      toolbarMobile: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      'ColumnChooser'],
      toolbar: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      {
        text: 'Associazione Multipla', tooltipText: 'Associazione Multipla', prefixIcon: 'e-icons e-group-2', id: 'prodNonAssociati',
      },
      'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'], // { text: 'Dettagli', tooltipText: 'Dettagli', prefixIcon: 'e-expand', id: 'Dettagli' }
      templateAttivo() {
        return {
          template: Vue.component('templateAttivo', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.attivo == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.attivo == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateDisponibile() {
        return {
          template: Vue.component('templateDisponibile', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.disponibile == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.disponibile == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateInEvidenza() {
        return {
          template: Vue.component('templateInEvidenza', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.inEvidenza == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.inEvidenza == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateVisibile() {
        return {
          template: Vue.component('templateVisibile', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.visibile == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.visibile == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateTipoProdotto() {
        return {
          template: Vue.component('templateTipoProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            computed: {
              statusVariant() {
                const statusColor = {
                  /* eslint-disable key-spacing */
                  Unificato      : 'light-primary',
                  Servizio : 'light-success',
                  Bundle     : 'light-danger',
                  Configurabile     : 'light-warning',
                  Virtuale      : 'light-info',
                  Downloadable       : 'light-success',
                  /* eslint-enable key-spacing */
                }

                return status => statusColor[status]
              },
            },
            template: `
              <div id="producter" class="">
                <b-badge :variant="statusVariant(data.tipoProdotto)">
                  {{ data.tipoProdotto }}
                </b-badge>
              </div>`,
          }),
        }
      },
    }
  },
  /* this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data }) */
  mounted() {
    /* axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ id: this.$route.params.id }]),
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'getProdotti',
          ACL: '',
        },
      }) */
    getProdotti().then(res => {
      // console.log(res)
      if (res.data.esito === 'OK') {
        this.products = [...res.data.prodotti]
      }
    }).catch(e => e)
    this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], false)
  },
  methods: {
    clearFilter(field) {
      this.$refs.overviewgrid.clearFiltering([field])
      const indexToRemove = this.filtersSearchBegin.findIndex(el => el.field === field)
      // console.log(indexToRemove)
      this.filtersSearchBegin[indexToRemove].active = false
      // this.filtersSearchBegin[indexToRemove].value = ''
      // this.filters.splice(indexToRemove, 0)
      // this.fiters = this.filters
    },
    dataBound() {
      this.$refs.overviewgrid.autoFitColumns()
    },
    onDeselectRow() {
      if (this.$refs.overviewgrid.getSelectedRecords().length === 0) {
        this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], false)
      }
    },
    onRowSelected() {
      this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], true)
      // console.log(this.selectedRow)
    },
    showDetails(params) {
      // console.log(params)
      this.$router.push({ name: 'details-products', params: { id: params } })
    },
    commandColumm(args) {
      // console.log('commandColumm', args)
      if (args.commandColumn.type === 'Dettagli') {
        this.showDetails(args.rowData.id)
      }
      if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
      // console.log('ciao sono menu click', args)
      if (args.item.id === 'Dettagli' && 'rowData' in args.rowInfo) {
        this.showDetails(args.rowInfo.rowData.id)
      } else if (args.item.id === 'DeleteCustom') {
        this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
      }
    },
    deleteProducts1() {
      const recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      // console.log(recordToDelete)
      recordToDelete.forEach(record => this.$refs.overviewgrid.deleteRecord('id', record))
    },
    actionBegin() {
    },
    actionComplete(args) {
      if (args.action === 'filter') {
        const columnInfo = this.$refs.overviewgrid.getColumnByField(String(args.currentFilteringColumn))
        const columnFiltered = {
          field: columnInfo.field,
          headerText: columnInfo.headerText,
          value: args.currentFilterObject.value,
          active: true,
        }
        // const headerTextFilterUsed = allColumns.find(el => el.field === args.currentFilteringColumn)
        const esistenzaFilter = this.filtersSearchBegin.findIndex(el => el.field === args.currentFilteringColumn)
        if (esistenzaFilter >= 0) {
          this.filtersSearchBegin[esistenzaFilter].value = args.currentFilterObject.value
          this.filtersSearchBegin[esistenzaFilter].active = true
        } else {
          this.filtersSearchBegin.push(columnFiltered)
        }
      }
      if (args.action === 'clearFilter') {
        const indexSearch = this.filtersSearchBegin.findIndex(el => el.field === args.currentFilterColumn.field)
        if (indexSearch >= 0) {
          this.filtersSearchBegin[indexSearch].active = false
        }
      }
    },
    deleteProducts(id) {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      // console.log(recordToDelete, 'wow')
      axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(recordToDelete),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Prodotto',
            metodo: 'deleteProdotti',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('res', res)
          recordToDelete.forEach(record => this.$refs.overviewgrid.deleteRecord('id', record))
          // mixins
          // this.productDeletedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Prodotto/i Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        }
      }).catch(e => e)
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteProducts(id)
        }
      })
    },
    toolbarClick(args) {
      // console.log('toolbarClick', args)
      if (args.item.id === 'Dettagli') {
        this.showDetails(args.rowData.id)
      }
      if (args.item.id === 'Aggiungi') {
        this.$router.push({ name: 'new-product' })
      }
      if (args.item.id === 'Elimina' && this.$refs.overviewgrid.getSelectedRecords().length > 0) {
        // console.log('delete')
        this.alertAndConfirm()
      }
      if (args.item.id === 'prodNonAssociati') {
        // this.$router.push({ name: 'new-product' })
        // console.log('ciao')
        this.products = []
        this.$router.push({ name: 'product-not-associates' })
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Prodotti_${moment().format('DD-MM-YYYY')}.pdf`,
        }
        this.$refs.overviewgrid.pdfExport(exportProperties)
        // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Prodotti_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgrid.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Prodotti_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgrid.csvExport(exportProperties)
      }
    },
    load() {
      // const gObj = this.$refs.overviewgrid.$el.ej2_instances[0]
      if (!this.isDesktop) {
        this.$refs.overviewgrid.$el.ej2_instances[0].rowRenderingMode = 'Vertical'
      }
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate, Edit, Search],
  },
}
</script>
