<template>
  <div
   :class="{ visibilityHidden: filterComputed.every(el => !el.active) }"
    class="" style="heigth: 100px"
  >
    <h6><b>Filtri Attivi:</b></h6>
    <div class="d-flex">
      <div
        v-for="(filter, index) in filterComputed"
        :key="index"
      >
        <h5
          v-if="filter.active"
        >
          <b-badge
            pill
            variant="primary"
            class="mr-1"
          >
              <div class="">
                {{ filter.headerText }}
                <span v-if="filter.value !== undefined || filter.value !== ''">
                  {{ ': '.concat(filter.value) || '' }}
                </span>
                <div
                  class="cancel-badge"
                  @click="clearFilter(filter.field)"
                >
                  <feather-icon
                    icon="XIcon"
                    size="10"
                  />
                </div>
              </div>
          </b-badge>
        </h5>
        <!-- <b-alert
          :show="filter.active"
          variant="primary"
          dismissible
          class="mr-1"
          style="width: 150px"
          @dismissed="clearFilter(filter.field);"
        >
          <div class="alert-body">
            {{ filter.headerText }}<span v-if="filter.value !== undefined || filter.value !== ''">{{ ': '.concat(filter.value) || '' }}</span>
          </div>
        </b-alert> -->

      </div>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    filtersUsed: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filterComputed() {
      return this.filtersUsed
    },
  },
  data() {
    return {
      showDismissibleAlert: true,
    }
  },
  methods: {
    clearFilter(field) {
      this.$emit('clear-filter', field)
    },
  },
}
</script>

<style scoped>
.visibilityHidden {
  visibility: hidden;
}

.cancel-badge {
  background-color: #00cfe8;
  border-radius: 50%;
  display: inline;
  padding-bottom: 1px;
  padding-left: 1px;
  padding-right: 1px;
  cursor: pointer;
}
</style>
